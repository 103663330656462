<template>
  <div
    :class="[
      'item_checkbox',
      { type_black: isBlackType || isNoticeType },
      { type_notice: isNoticeType },
    ]"
  >
    <input
      :id="props.id"
      v-model="model"
      type="checkbox"
    />
    <label
      :for="props.id"
      :class="[{ type_black: isBlackType || isNoticeType }]"
    >
      <span class="txt_checkbox">{{ props.title }}</span>
    </label>
  </div>
</template>
<!-- 사용시
<CheckboxBasic
    :id="'chkAgree1'"
    v-model="inputValues.chkAgree1.value"
    :title="'개인정보 수집·이용 동의 (필수)'"
  />
-->

<script setup>
const model = defineModel();
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  id: {
    type: String,
    default: '',
  },
  style: {
    type: String,
    default: '',
  },
});

const isNoticeType = computed(() => {
  return props.style === 'notice';
});

const isBlackType = computed(() => {
  return props.style === 'black';
});
</script>
<style lang="scss" scoped>
.item_checkbox {
  position: relative;
}
input[type='checkbox'] {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 1px;
  top: 1px;
  border: none;
  outline-offset: 4px;
}
label {
  &:before {
    content: '';
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    @include setSize(24px, 24px);
    margin-right: 8px;
    background-color: #fff;
    border: 1px solid #c1c1c1;
    border-radius: 4px;
  }
}
input[type='checkbox']:checked + label {
  &:before {
    border: none;
    @include backgroundImage(
      "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Crect width='24' height='24' rx='4' fill='%23F7DF4B'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M19.002 8.427 10.446 16.8l-.664-.648-.033.032-4.466-4.364 1.787-1.746 3.342 3.266 6.802-6.656 1.788 1.744z' fill='%23222'/%3E%3C/svg%3E",
      cover,
      center
    );
  }
  &.type_black {
    &:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Crect width='24' height='24' rx='4' fill='%230A0A0A'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='m19.002 8.428-8.556 8.371-.664-.648-.033.032-4.466-4.364 1.787-1.745 3.342 3.265 6.802-6.655 1.788 1.744z' fill='%23fff'/%3E%3C/svg%3E");
    }
  }
}

.txt_checkbox {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  letter-spacing: -0.3px;
}
// 공지사항 스타일
.item_checkbox.type_notice {
  label {
    margin-right: 8px;
  }
  .txt_checkbox {
    font-size: 16px;
    font-weight: 500;
  }
}
</style>
